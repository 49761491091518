import { View, Text, Pressable, Dimensions } from 'react-native';
import * as React from 'react';

function asMessage(title, about) {
  return 'Title: ' + title + '\nAbout: ' + about;
}

export function TimelinePin({ positionX, positionY, text = "hello", about, scale, color = "green", onPinPress }) {
  const width = 40 * scale;
  const height = 40 * scale;
  const radius = 20 * scale;

  return (
    <Pressable style={{
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      display: 'flex',
      width: width,
      height: height,
      left: positionX - radius,
      bottom: positionY + radius
    }}
      onPress={() => onPinPress(asMessage(text, about))}>
      <View style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: width,
        height: height,
        borderTopLeftRadius: radius,
        borderTopRightRadius: radius,
        borderBottomLeftRadius: radius,
        backgroundColor: color,
        transform: [{ rotateZ: '45deg' }]
      }} />
    </Pressable>)
}

export function LabelPin({ positionX, positionY, text = "hello", scale }) {
  const width = 40 * scale;
  const height = 40 * scale;
  const radius = 20;
  const windowHeight = Dimensions.get('window').height
  return (
    <Text style={{
      alignItems: 'center',
      position: 'absolute',
      display: 'flex',
      width: width,
      height: height,
      left: positionX - radius,
      bottom: positionY - height,
      textAlign: 'right',
      transform: [{ rotateZ: '90deg' }],
      color: 'white'
    }}>{text}</Text>)
}
