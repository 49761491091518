import { View } from 'react-native';
import { TimelinePin, LabelPin } from './Pin';


export function Timeline({ config, data, labels, windowWidth, windowHeight, onPinPress }) {
  const startDate = config.startDate
  const endDate = config.endDate
  const pointList = data
  const labelList = labels
  const startTime = startDate.getTime();
  const endTime = endDate.getTime();
  const offset = 10
  const radius = 10

  return (
    <View style={{ width: '100%', height: windowHeight }}>
      <View style={{ flexDirection: 'row', position: 'relative', width: '100%', height: windowHeight }}>
        {pointList.map((point, index) => (
          <TimelinePin
            key={index}
            positionX={mapTimeToPosition(
              point.startDate,
              startTime,
              endTime,
              windowWidth,
              (offset + radius)
            )}
            scale={1 + point.size / 10}
            positionY={windowHeight / 2}
            text={point.name}
            about={point.about}
            color={point.color}
            onPinPress={onPinPress}
          />
        ))}
      </View>
      <View style={{
        position: 'absolute',
        left: offset,
        right: offset,
        height: 8,
        backgroundColor: 'green',
        bottom: windowHeight / 2,
        borderRadius: radius
      }}
      />
      <View style={{ flexDirection: 'row', position: 'relative', width: '100%' }}>
        {labelList.map((label, index) => (
          <LabelPin
            key={index}
            scale={1 + label.size / 10}
            positionY={windowHeight / 2}
            positionX={mapTimeToPosition(
              label.startDate,
              startTime,
              endTime,
              windowWidth,
              (offset + radius)
            )}
            text={label.text}
          />
        ))}
      </View>
    </View>
  );
}

function mapTimeToPosition(pinDate, startTime, endTime, timelineWidth, offset) {
  console.log("timelineWidth= "+timelineWidth)
  console.log("position= " + ((pinDate.getTime() - startTime) / (endTime - startTime)) * (timelineWidth - 2*offset) + offset)

  return ((pinDate.getTime() - startTime) / (endTime - startTime)) * (timelineWidth - 2*offset) + offset;
}
