import * as SSF from 'ssf';

function excelDateToDate(input) {
  return new Date(SSF.format('yyyy-MM-dd', input))
}

function mapColor(type) {
  let ltype = type.toLowerCase()

  if (ltype === 'project') {
    return 'green'
  } else if (ltype === 'initiative') {
    return 'tomato'
  } else if (ltype === 'other') {
    return 'orange'
  } else {
    return 'white'
  }
}

export function parseConfig(config) {
  let startDate
  let endDate
  let radius = 10

  let offset = 5;
  config.forEach((line, index) => {
    console.log(line)
    if (line.Timeline !== undefined ? line.Timeline === "Date" : false) {
      startDate = excelDateToDate(line.Start)
      endDate = excelDateToDate(line.End)
    }
    if (line.Timeline === "Line") {
      radius = config[index+1].Start
      offset = config[index+1].End
    }
  }
  )
  return {
    startDate: startDate,
    endDate: endDate,
    radius: radius,
    offset: offset
  }
}

export function parseLabels(labels) {
  let keys = Object.keys(labels[0]);
  return keys.map((key) => {
    return {
      startDate: excelDateToDate(labels[0][key]),
      text: key,
      size: 1
    }
  })
}

export function parseData(data) {
  return data.map((poi) => {
    return {
      name: poi.Name,
      startDate: excelDateToDate(poi.Start),
      endDate: excelDateToDate(poi.End),
      type: poi.Type,
      about: poi.About,
      size: parseInt(poi.Size),
      color: mapColor(poi.Type)
    }
  })

}
