import './App.css';
import { useState } from 'react';
import { Modal, View, Dimensions, Text, Pressable } from 'react-native';
import { ImportFromFileBodyComponent } from './elements/FileUploader';
import { Timeline } from './elements/Timeline';
import { parseData, parseConfig, parseLabels } from './util/DataParser';

function App() {

  const [config, setConfig] = useState({
    startDate: new Date("2022-01-01T00:00:01"),
    endDate: new Date("2022-12-31T23:59:59")
  })
  const [labels, setLabels] = useState([{
    startDate: new Date("2022-01-01T00:00:01"),
    name: "January",
    size: 1
  }])
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedText, setSelectedText] = useState('');


  const setDataCallback = (content) => {
    console.log("Setting data...")
    const updatedData = parseData(content)
    setData(updatedData)
  }

  const setLabelsCallback = (labels) => {
    console.log("Setting labels...")
    setLabels(parseLabels(labels))
  }

  const setConfigCallback = (config) => {
    console.log("updating config...")
    setConfig(parseConfig(config))
  }

  const handlePinPress = (text) => {
    setSelectedText(text);
    setModalVisible(true);
  };


  var windowWidth = Dimensions.get("window").width;
  var windowHeight = Dimensions.get("window").height;;

  return (
    <View className="App">
      <header className="App-header">
        <Modal
          animationType='slide'
          transparent={true}
          visible={modalVisible}
        >
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 10 }}>
              <Text>{selectedText}</Text>
              <Pressable onPress={() => setModalVisible(false)}>
                <Text style={{ color: 'blue' }}>Close</Text>
              </Pressable>
            </View>
          </View>
        </Modal>
        <View style={{ alignItems: 'center' }}>
          <ImportFromFileBodyComponent
            contentReceivedCallback={setDataCallback}
            configReceivedCallback={setConfigCallback}
            labelsReceivedCallback={setLabelsCallback} />
        </View>
        <Timeline
          config={config}
          data={data}
          labels={labels}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          onPinPress={handlePinPress} />
      </header >
    </View >
  );
}

export default App;
