import * as XLSX from "xlsx";

export const ImportFromFileBodyComponent = ({ contentReceivedCallback, labelsReceivedCallback, configReceivedCallback }) => {
  let fileReader;


  const handleFileRead = (e) => {
    // const content = fileReader.result;
    // // console.log(content);
    // contentReceivedCallback(content);
    //
    // parse the result as binary string to .xlsx workbook
    const workbook = XLSX.read(e.target.result, {
      type: "binary",
      raw: false,
      cellText: false,
      cellDate: false,
    })
    // parse the .xlsx workbook to JSON
    let objectsList = []
    workbook.SheetNames.forEach((sheet) => {
      if (sheet.toLowerCase() === "config") {
        const configObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
        console.log(workbook.Sheets[sheet])
        configReceivedCallback(configObject)
      } else if (sheet.toLowerCase() === "labels") {
        const configObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
        labelsReceivedCallback(configObject)
      }
      else {
        const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
        // you now have an array of objects representing the rows in the sheet
        objectsList = objectsList.concat(rowObject)
      }
    })
    contentReceivedCallback(objectsList);
  }


  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onload = handleFileRead;
    fileReader.readAsArrayBuffer(file);
  };


  return (
    <div className='upload-expense'>
      <input type='file'
        id='file'
        className='input-file'
        accept=' .xlsx'
        onInput={e => handleFileChosen(e.target.files[0])}
        onClick={e => e.target.value = null}

      />

    </div>);
}
